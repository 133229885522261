import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import PropTypes from 'prop-types';

function LoginButton(props) {
  const { onSuccess, onFailure, disabled, render } = props;
  const login = useGoogleLogin({
    onSuccess,
    onError: onFailure,
  });

  return render({ onClick: login, disabled });
}

export const GoogleLogin = ({ clientID, ...restProps }) => (
    <GoogleOAuthProvider clientId={clientID}>
      <LoginButton {...restProps} />
    </GoogleOAuthProvider>
  )

GoogleLogin.propTypes = {
  clientID: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onFailure: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  render: PropTypes.func.isRequired,
};

export async function getProfileData(accessToken) {
  const response = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  if (!response.ok) {
    throw new Error('failed to fetch profile data from google api');
  }
  return response.json();
}
