import Layout from 'layout';
import Link from 'components/ui/link';
import { JobList } from 'styles/jobs';

const Jobs = () => (
  <Layout
    metaTitle="Careers | Work With Us"
    canonical="/jobs/"
    metaDescription="Work to create world-class session recording software with us. Explore job opportunities at LiveSession."
  >
    <section>
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-8 text-left p-4 m-auto">
            <h1 className="mb-5 text-center">Join LiveSession</h1>
            <JobList>
              <li>
                <Link href="/jobs/content-specialist/" className="row">
                  <div className="col-8">
                    <h2>Content Specialist</h2>
                    <span>remote</span>
                  </div>
                  <div
                    className="col-4"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <span
                      className="badge badge-secondary"
                      style={{ padding: '.35rem .75rem', lineHeight: 'inherit' }}
                    >
                      Marketing
                    </span>
                  </div>
                </Link>
              </li>

              <li>
                <Link href="/jobs/product-marketing-manager/" className="row">
                  <div className="col-8">
                    <h2>Product Marketing Manager</h2>
                    <span>remote</span>
                  </div>
                  <div
                    className="col-4"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <span
                      className="badge badge-secondary"
                      style={{ padding: '.35rem .75rem', lineHeight: 'inherit' }}
                    >
                      Marketing
                    </span>
                  </div>
                </Link>
              </li>

              {/* <li>
                <Link href="/jobs/junior-react-developer/" className="row">
                  <div className="col-8">
                    <h2>Junior React Developer</h2>
                    <span>remote</span>
                  </div>
                  <div
                    className="col-4"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <span
                      className="badge badge-secondary"
                      style={{ padding: '.35rem .75rem', lineHeight: 'inherit' }}
                    >
                      Engineering
                    </span>
                  </div>
                </Link>
              </li> */}

              {/* <li> */}
              {/* <Link href="/jobs/go-developer/" className="row"> */}
              {/*  <div className="col-8"> */}
              {/*    <h2>Go Developer</h2> */}
              {/*    <span>remote</span> */}
              {/*  </div> */}
              {/*  <div */}
              {/*    className="col-4" */}
              {/*    style={{ */}
              {/*      display: 'flex', */}
              {/*      alignItems: 'center', */}
              {/*      justifyContent: 'flex-end', */}
              {/*    }} */}
              {/*  > */}
              {/*    <span */}
              {/*      className="badge badge-secondary" */}
              {/*      style={{ padding: '.35rem .75rem', lineHeight: 'inherit' }} */}
              {/*    > */}
              {/*      Engineering */}
              {/*    </span> */}
              {/*  </div> */}
              {/* </Link> */}
              {/* </li> */}

              <li>
                <Link href="/jobs/account-executive/" className="row">
                  <div className="col-8">
                    <h2>Account Executive</h2>
                    <span>remote</span>
                  </div>
                  <div
                    className="col-4"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <span
                      className="badge badge-secondary"
                      style={{ padding: '.35rem .75rem', lineHeight: 'inherit' }}
                    >
                      Sales
                    </span>
                  </div>
                </Link>
              </li>

              {/* <li>
                <Link href="/jobs/junior-software-engineer" className="row">
                  <div className="col-8">
                    <h2>Junior Software Engineer</h2>
                    <span>remote</span>
                  </div>
                  <div
                    className="col-4"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <span
                      className="badge badge-secondary"
                      style={{ padding: '.35rem .75rem', lineHeight: 'inherit' }}
                    >
                      Engineering
                    </span>
                  </div>
                </Link>
              </li> */}

            </JobList>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default Jobs;
